<template>
    <h1>Search</h1>
    <h1>Search term: " {{ query }} "</h1>
    <div class="w-full h-full md:h-auto grid grid-cols-1 md:grid-cols-3 gap-4 px-2 py-4">
        <smallcard v-for="product in products" :key="product.id" :product="product"/>
    </div>
    
</template>

<script>
    import axios from 'axios';
    import Smallcard from "../components/Smallcard";
    import {createToast} from "mosha-vue-toastify";

    export default {
        name: "Search",
        components: {
            Smallcard
        },
        data() {
            return {
                products: [],
                query: ''
            }
        },
        mounted() {
            document.title = 'Search | Durban';
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);

            if (params.get('search')) {
                this.query = params.get('search');
                this.performSearch()
            }
        },
        methods: {
            async performSearch() {
                this.$store.commit('setIsLoading', true);
                await axios
                    .post('/api/v1/products/search/', {'query': this.query})
                    .then(response => {
                        this.products = response.data;
                        console.log(this.products.length)

                    })
                    .catch(error => {
                        console.log(error);

                        createToast('Something went wrong. Please Try again.',
                            {
                                position: 'top-right',
                                type: 'danger',
                                transition: 'bounce',
                            })
                    });


                this.$store.commit('setIsLoading', false);
            }
        }
    }
</script>

<style scoped>

</style>
